import React from 'react';
import {
  BrowserRouter, Router
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './components/Layout/Theme';
import Routes from './routes';
import history from './utils/history';

function App() {
  //const basename = process.env.NODE_ENV === 'development' ? '/' : ('/');
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Router history={history}>
          <Routes />
        </Router>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
