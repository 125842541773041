import React from "react";
import { VectorMap } from "react-jvectormap";

const mapData = {
    'CA': 11100,   // Canada
    'DE': 2510,    // Germany
    'FR': 3710,    // France
    'AU': 5710,    // Australia
    'GB': 8310,    // Great Britain
    'RU': 9310,    // Russia
    'BR': 6610,    // Brazil
    'IN': 7810,    // India
    'CN': 4310,    // China
    'US': 839,     // USA
    'SA': 410      // Saudi Arabia
};

const handleClick = (e, countryCode) => {
    console.log(countryCode);
};

const defaultColors = {
    markerColor: '#23b7e5',      // the marker points
    bgColor: 'transparent',  // the background
    scaleColors: ['#878c9a'],    // the color of the region in the serie
    regionFill: '#bbbec6'       // the base region color
};
const Map = () => {
    return (
        <>
            <VectorMap
                map={"world_mill"}
                backgroundColor="transparent" //change it to ocean blue: #0077be
                zoomOnScroll={false}
                containerStyle={{
                    width: "100%",
                    height: "370px"
                }}
                onRegionClick={handleClick} //gets the country code
                containerClassName="map"
                regionStyle={{
                    initial: {
                        'fill': defaultColors.regionFill,
                        'fill-opacity': 1,
                        'stroke': 'none',
                        'stroke-width': 1.5,
                        'stroke-opacity': 1
                    },
                    hover: {
                        'fill-opacity': 0.8
                    },
                    selected: {
                        fill: 'blue'
                    },
                    selectedHover: {}
                }}
                regionsSelectable={true}
                series={{
                    regions: [
                        {
                            values: mapData,
                            scale: defaultColors.scaleColors,
                            normalizeFunction: 'polynomial'
                        }
                    ]
                }}
                onRegionTipShow={(e, el, code) => { el.html(el.html() + ' : 100 Visitors'); }}
                markers={[
                    { latLng: [41.90, 12.45], name: 'Vatican City' },
                    { latLng: [43.73, 7.41], name: 'Monaco' },
                    { latLng: [151.20,33.86 ], name: 'Sydney' },
                    { latLng: [34.05, 118.24], name: 'Sichuan' },
                    { latLng: [28.7041, 77.1025], name: 'Delhi' },
                    { latLng: [40.7128, 74.0060], name: 'Bishkek' }
                ]}
                markerStyle={{
                    initial: {
                        fill: defaultColors.markerColor,
                        stroke: defaultColors.markerColor
                    }
                }}
            />
        </>
    );
};
export default Map;