import React from 'react';
import { Line as LineChart, Bar as BarChart } from 'react-chartjs-2';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


export const BarGraph = () => {

    const classes = useStyles();
    const [country, setCountry] = React.useState('USA');
    const rFactor = () => Math.round(Math.random() * 100)

    const Bar = {
        options: {
            legend: {
                display: false
            }
        }
    }

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: "Applications by Country",
            backgroundColor: '#23b7e5',
            borderColor: '#23b7e5',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }, {
            label: "Screening dataset",
            backgroundColor: '#5d9cec',
            borderColor: '#5d9cec',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }]
    }
    const data1 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: "Applications by Country",
            backgroundColor: '#23b7e5',
            borderColor: '#23b7e5',
            data: [10, 40, 60, 80, 20, 90, 75]
        }, {
            label: "Screening dataset",
            backgroundColor: '#5d9cec',
            borderColor: '#5d9cec',
            data: [25, 35, 45, 55, 65, 75, 85]
        }]
    }
    const data2 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: "Applications by Country",
            backgroundColor: '#23b7e5',
            borderColor: '#23b7e5',
            data: [25, 55, 45, 55, 65, 75, 85]
        }, {
            label: "Screening dataset",
            backgroundColor: '#5d9cec',
            borderColor: '#5d9cec',
            data: [10, 20, 70, 80, 20, 40, 70]
        }]
    }

    const [bardata, setBarData] = React.useState(data);

    const handleChange = (event) => {
        setCountry(event.target.value);
        console.log(event.target.value);
        if (event.target.value === 'Sweden') {
            setBarData(data1);
        } else if (event.target.value === 'Denmark') {
            setBarData(data2);
        } else {
            setBarData(data);
        }
    };
    return (
        <>
            <Box display="flex" flexDirection="row-reverse" mt={-11} >
                <Box p={1} >
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={country}
                            onChange={handleChange}
                        >
                            <MenuItem value={'USA'}>Switzerland</MenuItem>
                            <MenuItem value={'Sweden'}>Germany</MenuItem>
                            <MenuItem value={'Denmark'}>Qatar</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <BarChart data={bardata} options={Bar.options} width={600} height={300} />
        </>
    );
};

export const LineGraph = () => {

    const classes = useStyles();
    const [country, setCountry] = React.useState('');
    const [customer, setCustomer] = React.useState('c1');
    const rFactor = () => Math.round(Math.random() * 100)

    const Line = {
        options: {
            legend: {
                display: false
            }
        }
    }

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'Screening as service dataset',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }, {
            label: 'Biochip lifetime dataset',
            backgroundColor: 'rgba(35,183,229,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }]
    }
    const data1 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'Screening as service dataset',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: [10, 40, 60, 80, 20, 90, 75]
        }, {
            label: 'Biochip lifetime dataset',
            backgroundColor: 'rgba(35,183,229,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: [25, 35, 45, 55, 65, 75, 85]
        }]
    }
    const data2 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'Screening as service dataset',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: [25, 55, 45, 55, 65, 75, 85]
        }, {
            label: 'Biochip lifetime dataset',
            backgroundColor: 'rgba(35,183,229,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: [10, 20, 70, 80, 20, 40, 70]
        }]
    }

    const [linedata, setLineData] = React.useState(data);

    const handleChange = (event) => {
        setCountry(event.target.value);
        setCustomer('');
        console.log(event.target.value);
        if (event.target.value === 'Sweden') {
            setLineData(data1);
        } else if (event.target.value === 'Denmark') {
            setLineData(data2);
        } else {
            setLineData(data);
        }
    };
    const handleChangeCustomer = (event) => {
        setCustomer(event.target.value);
        setCountry('');
        if (event.target.value === 'c3') {
            setLineData(data1);
        } else if (event.target.value === 'c1') {
            setLineData(data2);
        } else {
            setLineData(data);
        }
    };
    return (
        <>
            <Box display="flex" flexDirection="row-reverse" mt={-11} >
                <Box p={1} >
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customer}
                            onChange={handleChangeCustomer}
                        >
                            <MenuItem value={'c1'}>Customer 1</MenuItem>
                            <MenuItem value={'c2'}>Customer 2</MenuItem>
                            <MenuItem value={'c3'}>Customer 3</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/* <Box p={1} >
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={country}
                            onChange={handleChange}
                        >
                            <MenuItem value={'USA'}>Switzerland</MenuItem>
                            <MenuItem value={'Sweden'}>Germany</MenuItem>
                            <MenuItem value={'Denmark'}>Qatar</MenuItem>
                        </Select>
                    </FormControl>
                </Box> */}
            </Box>
            <LineChart data={linedata} options={Line.options} width={600} height={300} />
        </>
    );
};