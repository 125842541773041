import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Store } from "../../store/Store";
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function AddTransaction() {

    const { state, dispatch } = React.useContext(Store);

    const [lstate, setLstate] = React.useState({
        transaction_name: "", module_id: '', sts: "", dalog: false, transaction_namespn: "", module_idspn: "",
        snkbrdta: { sts: "", msg: "" },
        err: {
            transaction_name: false, module_id: false
        }
    });

    const handlechange = (event) => {
        if (lstate.transaction_name === null || lstate.transaction_name === '') {
            setLstate({...lstate, transaction_namespn: "Please fill Transaction Name", err: { transaction_name: true } });
        } else if (lstate.module_id === '') {
            setLstate({...lstate, module_idspn: "Please select module", err: { module_id: true } });
        } else {
            console.log(lstate);
            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/transaction', lstate).then((response) => {
                console.log("After: ", response);

               
                setLstate({...lstate,
                    snkbrdta: { sts: "success", msg: "Saved Successfully" }
                });
                handleClose();
            })
        }

    }

    const handleClose = (event, reason) => {
        console.log(reason);
        if (reason === 'clickaway') {
            return;
        }

        setLstate({   ...lstate,
            dalog: !lstate.dalog
        });
    };
    return (
        <div>
            <Snackbar open={lstate.dalog} autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleClose} >
                <Alert severity={lstate.snkbrdta.sts} onClose={handleClose}

                    variant="filled">
                    {lstate.snkbrdta.msg}
                </Alert>
            </Snackbar>
            <Card >
                <CardHeader title="Add Transaction" />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} >
                            <TextField
                                error={lstate.err.transaction_name}
                                helperText={lstate.transaction_namespn}
                                type="text"
                                label="Transaction Name"
                                fullWidth
                                name="transaction_name"
                                variant="outlined"
                                value={lstate.transaction_name}
                                onChange={(event) =>
                                    setLstate({...lstate,
                                        [event.target.name]: event.target.value,
                                        transaction_namespn: "",
                                        err: { transaction_name: false }
                                    })
                                }
                                autoFocus
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField
                                name="module_id"
                                select
                                label="Module"
                                variant="outlined"
                                value={lstate.module_id}
                                error={lstate.err.module_id}
                                helperText={lstate.module_idspn}
                                onChange={(event) =>
                                    setLstate({...lstate,
                                        [event.target.name]: event.target.value,
                                        module_idspn: "",
                                        err: { module_id: false }
                                    })
                                }
                                fullWidth
                            >
                                {state.user.Module.map((option) => (
                                    <MenuItem key={option.module_id} value={option.module_id}>
                                        {option.module_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} justify="flex-end">
                        <Grid item  >
                            <Button variant="contained" color="primary" onClick={handlechange}>Save</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}