import { createMuiTheme } from '@material-ui/core/styles';

const arcBlue = "#0B72B9";
//const arcBlue ="#3f51b5";
//const arcOrange = "#FFBA60";
const arcOrange = "#e65100";
const arcGrey = "#868686";

export default createMuiTheme({
    palette: {
        common: {
            blue: `${arcBlue}`,
            orange: `${arcOrange}`
        },
        type: "light",
        primary: {
            main: `${arcBlue}`
        },
        secondary: {
            main: `${arcOrange}`
        }
    },
});