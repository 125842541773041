import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

import PublicPage from './components/Layout/PublicPage';
import Header from './components/Layout/Header';

import Home from './components/pages/Home';
import SignIn from './components/pages/Login';
import LogIn from './components/pages/Login1';

import AddModule from './components/pages/AddModule';
import ModuleListTable from './components/pages/modulelist';

import AddTransaction from './components/pages/AddTransaction';
import TransactionListTable from './components/pages/transactionlist';

import AddRole from './components/pages/AddRole';
import RoleListTable from './components/pages/rolelist';

import UserListTable from './components/pages/usersList';
import Maintenance from './components/pages/Maintenance';

import Administrator from './components/Dashboard/Administrator';
import Administratorv2 from './components/Dashboard/Administrator-v2';

// import AddRole from './components/pages/AddRole';
// import AddTransaction from './components/pages/AddTransaction';
// import AddAccount from './components/pages/AddAccount';
// import ModuleListTable from './components/pages/modulelist';
// import RoleListTable from './components/pages/rolelist';

// import UserListTable from './components/pages/userList';

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname

const listofPages = [
    '/',
    '/login',
];

const Routes = ({ location }) => {

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <PublicPage>
                <Switch>
                    {/* <Route exact path="/" component={props => ( <Maintenance /> )} /> */}
                    <Route exact path="/" render={() => <Redirect to="/login" />} />
                    <Route exact path="/login" component={props => ( <LogIn /> )} />
                </Switch>
            </PublicPage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout

            <React.Fragment>
                <Header>
                    <Switch>
                        <Route exact path="/home" component={props => ( <Home /> )} />

                        <Route exact path="/TSN00004" component={props => ( <AddModule /> )} />
                        <Route exact path="/TSN00005" component={props => ( <ModuleListTable /> )} />

                        <Route exact path="/TSN00006" component={props => ( <AddTransaction /> )} />
                        <Route exact path="/TSN00007" component={props => ( <TransactionListTable /> )} />

                        <Route exact path="/TSN00008" component={props => ( <AddRole /> )} />
                        <Route exact path="/TSN00009" component={props => ( <RoleListTable /> )} />

                        <Route exact path="/TSN00003" component={props => ( <UserListTable /> )} />

                        <Route exact path="/administrator-v1" component={props => ( <Administrator /> )} />
                        <Route exact path="/administrator-v2" component={props => ( <Administratorv2 /> )} />

                        <Route exact path="/maintenance" component={props => ( <Maintenance /> )} />

                        <Redirect to="/maintenance" />
                    </Switch>
                </Header>
            </React.Fragment>
        )
    }
}

export default withRouter(Routes);