import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop:10
    },
}));

function Maintenance() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3} direction="column" justify="center" alignItems="center">
                <Grid item xs={12} >
                    <Typography variant="h3" >PAGE IS UNDER MAINTENANCE</Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography variant="h5" gutterBottom>We'll back online shortly!</Typography>
                </Grid>
            </Grid>
        </div>
    )
}


// <div className="abs-center">
//     <div className="text-center my-3">
//         <h1 className="mb-3">
//             <sup>
//                 <em className="fa fa-cog fa-2x text-muted fa-spin text-info"></em>
//             </sup>
//             <em className="fa fa-cog fa-5x text-muted fa-spin text-purple"></em>
//             <em className="fa fa-cog fa-lg text-muted fa-spin text-success"></em>
//         </h1>
//         <div className="text-bold text-lg mb-3">SITE IS UNDER MAINTENANCE</div>
//         <p className="lead m-0">We'll back online shortly!</p>
//     </div>
// </div>

export default Maintenance;
