
import React, { Component } from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';


const fltbutton = {
    position: 'fixed',
    zIndex: 1,
    width: '60px',
    height: '60px',
    bottom: '60px',
    right: '40px',
}


class ModuleListTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            catdta: [],
            model: false,
            dalog: false,
            rows: [],
            serresp: [],
            opn: false,
            chkbx: {},
            catJsnDta: {},
            snkbrdta:{sts:"",msg:""},
            catspn: "" , profspn: "" ,
            err:{
               module_name: false, proftyp: false
           }
        };

        this.handleFldChange = this.handleFldChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlactv = this.handlactv.bind(this);
    }

    addRow = (value) => {
        this.setState(state => {
            return {
                rows: [...this.state.rows, value]
            };
        });
    };

    handleFldChange(event) {
        let dta = this.state.catJsnDta;
        dta.active = !this.state.catJsnDta.active;
        this.setState({
            catJsnDta: dta
        });
    }

    handlechange = (event) => {
        let dta = this.state.catdta[event.currentTarget.value];
        dta=Object.assign({
          rwindx:event.currentTarget.value
        },dta);
        this.setState({ catJsnDta: dta });
        this.handleClose();
    }

    handleSubmit() {
        this.setState({catspn: "" , profspn: "" });
        if(this.state.catJsnDta.module_name===null || this.state.catJsnDta.module_name ===''){
        
         this.setState({catspn: "Module name is Required",err:{module_name:true} });
        }else{
        console.log(this.state.catJsnDta);
        let tst = this.state.catJsnDta;
        // console.log(tst.amt.props.value);
        
        let dta = this.state.catdta;
          var mdData=tst
        console.log(mdData);
        axios.put('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/module', tst).then((response) => {
            console.log(response);
            if (response.data.error !==true) {
                dta[tst.rwindx] = tst;
              console.log(dta);
                this.setState({
                    catdta: dta
                });
                this.setState({
                    snkbrdta:{sts:"success",msg:"Module Id: "+[this.state.catJsnDta.module_id]+", "+response.data.message}
                });
                this.handleClose();
                this.handleCloseSnk();
                
            } else {
                this.setState({
                    snkbrdta:{sts:"error",msg:response.data.message}
                });
                this.handleCloseSnk();
            }
           
        });
    }
    }
    handlactv(event) {


        let dta = this.state.catdta;
        let data = dta[event.currentTarget.value];

        data.active = !this.state.catdta[event.currentTarget.value].act.props.checked;
        console.log(data);
        // axios.post('http://localhost:8045/updCatSts', data).then((response) => {
        //     // event.preventDefault();
        //     console.log(response);
        //     dta[event.target.value].active = !this.state.catdta[event.target.value].act.props.checked;
        //     dta[event.target.value].act = <Switch
               
        //         checked={!this.state.catdta[event.target.value].act.props.checked}
        //         onChange={this.handlactv}
        //         color="primary"
        //         name={[event.target.name]}
        //         inputProps={{ 'aria-label': 'primary checkbox' }}
        //         value={[event.target.value]}
        //     />
        //     console.log(dta);
        //     this.setState({
        //         catdta: dta
        //     });

        // });

        // console.log( this.state.catdta)
    }

    componentDidMount() {
        axios.get('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/moduleList').then((response) => {
            // event.preventDefault();
            console.log(response);
            this.setState({ catdta: response.data.result });

        });

    }



    componentWillUnmount() {
        //this.eventSource.removeAllListeners();
        //this.eventSource.close();
    }

    handleHistPush = () => {
        //  history.push(`/catagory`);
    };

    handleClose = () => {
        this.setState({
            model: !this.state.model
        });
    };

    handleCloseSnk = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({
            dalog: !this.state.dalog
        });
      };

    render() {

        const columns = [
            {
                name: "module_id",
                label: "ID",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    sort: true,
                    draggable:true,
                }
            },
            {
                name: "module_name",
                label: "Module Name",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    sort: true,
                    draggable:true,
                }
            }
            ,
            {
                name: "action",
                label: "Action",
                options: {
                    filter: true,
                    sort: false,
                    draggable:true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                       return (   
                        <IconButton
                        id={tableMeta.rowData[0]} 
                        aria-label="add"
                        // onClick={(event) => this.handlechange(idy)}
                        onClick={this.handlechange}
                        value={tableMeta.rowIndex}
                    >
                        <VisibilityIcon
                        />
                    </IconButton>
              
                      ) 
                   }  
                },
               

            },

        ];

        const options = {
            // filter: true,
            //   filterType: 'dropdown',
            responsive: 'vertical',
            sortOrder: {
                name: 'id',
                direction: 'asc'
            },
            draggableColumns: {
                enabled: true
              },
            selectableRows: false
        };

        return (

            <div>
            {/* <Container fixed> */}
                <Snackbar open={this.state.dalog} autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={this.handleCloseSnk} >
                    <Alert severity={this.state.snkbrdta.sts} onClose={this.handleCloseSnk}

                        variant="filled">
                        {this.state.snkbrdta.msg}
                    </Alert>
                </Snackbar>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.model}>

                    <Card >
                        <CardHeader

                            title="Module Details"
                        >

                        </CardHeader>

                        <CardContent>
                            {/* <form> */}

                            <Grid container spacing={3}>
                                {/* Chart */}

                                <Grid item xs={12}>
                                    <TextField
                                     error={this.state.err.module_name}
                                     helperText= {this.state.catspn}
                                        type="text"
                                        label="Module Name"
                                        fullWidth
                                        name="module_name"
                                        variant="outlined"
                                        value={this.state.catJsnDta.module_name}
                                        onChange={(event) =>
                                            this.setState(prevState => ({
                                                catspn: "",
                                                err:{module_name:false},
                                                catJsnDta: {
                                                    ...prevState.catJsnDta,
                                                    module_name: event.target.value
                                                }
                                            }))
                                        }
                                        required
                                        autoFocus
                                    />
                                    
                                </Grid>
                                
                            </Grid>
                            <Grid container spacing={8} justify="flex-end">
                                <Grid item  >
                                    <Button variant="contained" color="primary"
                                        onClick={this.handleSubmit}
                                    // type="submit"
                                    >
                                        Update
                                </Button>
                                </Grid>
                            </Grid>
                            {/* </form> */}
                        </CardContent>
                       
                    </Card>

                </Dialog>

                <MUIDataTable
                    title={"Modules"}
                    data={this.state.catdta}
                    columns={columns}
                    options={options}
                />

              
            {/* </Container> */}
            </div>

        );
    }

}

export default ModuleListTable;
