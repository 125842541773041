

import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

class AddModule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            module_name: "",
           
            sts: "", dalog: false,
            //err: false,
            module_namespn: "",
           
            err:{
                module_name: false
            }

        };
        this.handleFldChange = this.handleFldChange.bind(this);
        // this.handlechange = this.handlechange.bind(this);
    }

    handleFldChange(event) {
       // this.setState({ catnm: event.state.catnm, proftyp: event.state.proftyp });
    }

    handlechange = (event) => {
        console.log("Before: ",this.state);
       
        //this.setState({ catspn: "", profspn: "" });
        if (this.state.module_name === null || this.state.module_name === '' ) {

            this.setState({ module_namespn: "Please fill the Module Name",err:{module_name:true} });
        } else {
            

            console.log("After: ",this.state);


            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/module', this.state).then((response) => {
                // event.preventDefault();
                console.log(response);
                if (response.data.error === false) {
                    this.setState({  module_name: "",  sts: "success", msg: response.data.message });
                } else {
                    this.setState({ sts: "error", msg: response.data.message, dalog: true });
                }

            });
        }

    }

   

    componentDidMount() {


    }
    componentWillUnmount() {
        //this.eventSource.removeAllListeners();
        //this.eventSource.close();
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            dalog: !this.state.dalog
        });
    };
    handleSubmit(event) {

    }

    render() {

        return (

            <div>
            {/* <Container> */}
                <Snackbar open={this.state.dalog} autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={this.handleClose} >
                    <Alert severity={this.state.sts} onClose={this.handleClose}

                        variant="filled">
                        {this.state.msg}
                    </Alert>
                </Snackbar>
                <Card >
                    <CardHeader

                        title="Add Module"
                    >

                    </CardHeader>

                    <CardContent>
                        
                        <Grid container spacing={3}>


                            <Grid item xs={12} >
                                
                                        <TextField
                                         error={this.state.err.module_name}
                                         helperText= {this.state.module_namespn}
                                            type="text"
                                            label="Module Name"
                                            fullWidth
                                            name="module_name"
                                            variant="outlined"
                                            value={this.state.module_name}
                                            onChange={(event) =>
                                                this.setState({
                                                    [event.target.name]: event.target.value,
                                                    module_namespn: "",
                                                    err:{module_name:false}
                                                })
                                            }
                                            autoFocus
                                            required
                                        />
                                    </Grid>

                        </Grid>


                        <Grid container spacing={8} justify="flex-end">
                            <Grid item  >
                                <Button variant="contained" color="primary"
                                    onClick={this.handlechange}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                    </CardContent>

                </Card>
            {/* </Container> */}
            </div>

        );
    }

}

export default AddModule;