// import { useHistory } from "react-router-dom";

// export default function History(){
//     let history = useHistory();
//     return history;
// }

import createHistory from 'history/createBrowserHistory';

export default createHistory();
