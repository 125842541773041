import React from 'react';
import { Store } from "../../store/Store";
import { Redirect } from 'react-router-dom';

export default function PublicPage(props){

    const { state, dispatch } = React.useContext(Store);

    if(state.user.length!=0){
        return <Redirect to="/home" />;
    }

    return(
        <>
            {props.children}
        </>
    )
}