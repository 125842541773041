import React from 'react';
//import HomeComp from './Home-Comp';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from "mui-datatables";
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import { Line as LineChart, Bar as BarChart } from 'react-chartjs-2';
import {BarGraph,LineGraph} from '../Map/Graph';
import Map from '../Map/Map';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    c1: {
        fontSize: 32,
        fontWeight: 100,
        marginTop: 5,
        marginLeft: 7
    },
    color1: {
        backgroundImage: 'linear-gradient(135deg,#36d1dc 10%,#5b86e5)'
    },
    color2: {
        backgroundImage: 'linear-gradient(135deg,#ee9ae5 10%,#5961f9)'
    },
    color3: {
        backgroundImage: 'linear-gradient(135deg,#80d0c7 10%,#5a8ca9)'
    },
    color4: {
        backgroundImage: 'linear-gradient(135deg,#06beb6 10%,#48b1bf)'
    },
    divider: {
        backgroundColor: 'black',
        height: '1px',
        //backgroundImage: 'linear-gradient(135deg,#36d1dc 10%,#5b86e5)'
    },
    table:{
        border: 'solid 1px rgb(0 0 0 / 14%)',
        boxShadow: 'none'
    },
    icon: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    }
}));

// random values for demo
const rFactor = () => Math.round(Math.random() * 100)

// Line chart
// -----------------------------------
export const Line = {
    data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'Screening as service dataset',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }, {
            label: 'Biochip lifetime dataset',
            backgroundColor: 'rgba(35,183,229,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }]
    },
    options: {
        legend: {
            display: false
        }
    }
}

// Bar chart
// -----------------------------------
export const Bar = {
    data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: "Applications by Country",
            backgroundColor: '#23b7e5',
            borderColor: '#23b7e5',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }, {
            label: "Screening dataset",
            backgroundColor: '#5d9cec',
            borderColor: '#5d9cec',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }]
    },
    options: {
        legend: {
            display: false
        }
    }
}


function Administratorv2() {

    const classes = useStyles();

    const data = [
        { application: "Application 1", customer: "Test Corp", country: "Russia", transactions: "2000" },
        { application: "Application 1", customer: "Test Corp", country: "Vietnam", transactions: "3000" },
        { application: "Application 1", customer: "Test Corp", country: "Brazil", transactions: "4000" },
        { application: "Application 1", customer: "Test Corp", country: "Germany", transactions: "5000" },
        { application: "Application 1", customer: "Test Corp", country: "USA", transactions: "6000" },
    ];
    const columns = [
        {
            name: "application",
            label: "Application",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "customer",
            label: "Customer",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "country",
            label: "Country",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "transactions",
            label: "Transactions",
            options: {
                filter: true,
                sort: false,
            }
        },
    ];
    const tableBodyHeight='360px';
    const options = {
        responsive: 'vertical',
        sortOrder: {
            name: 'name',
            direction: 'asc'
        },
        draggableColumns: {
            enabled: true
          },
        selectableRows: false,
        download: false,
        print: false,
        filter: false,
        tableBodyHeight
    };


    const ColorCard = (props) => {

        const { title, subtitle, value, icon, pval, styles } = props;

        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        };

        function handleListKeyDown(event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            }
        }

        // return focus to the button when we transitioned from !open -> open
        const prevOpen = React.useRef(open);
        React.useEffect(() => {
            if (prevOpen.current === true && open === false) {
                anchorRef.current.focus();
            }

            prevOpen.current = open;
        }, [open]);


        return (
            <Card variant="outlined" className={styles}>
                <CardContent>
                    <Grid container direction="column" >
                        <Grid item >
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle1">
                                            {title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <img src={icon} className={classes.icon} />
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} style={{ marginBottom: '15px' }} gutterBottom />
                        </Grid>
                        <Grid item justify="flex-start" alignItems="flex-start">
                            <Typography className={classes.c1} gutterBottom >
                                {value}
                            </Typography>
                        </Grid>
                        <Grid item justify="flex-start" alignItems="flex-start" style={{ marginTop: '-12px' }} >
                            <Typography variant="caption" style={{ marginLeft: '8px' }}>
                                {subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                {/* <Grid item xs direction="row" justify="center" alignItems="center">
                                    <Typography variant="caption" style={{marginLeft: '10px'}}>
                                        {pval}
                                    </Typography>
                                    <TrendingUpOutlinedIcon fontSize="small" style={{paddingTop: '4px'}} />
                                </Grid> */}
                                <Grid container xs direction="row" style={{marginLeft: '15px',marginTop: '11px'}}>
                                    <Typography variant="subtitle1">
                                         {pval}
                                    </Typography>
                                    <TrendingUpOutlinedIcon  />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    View
                                </Button>
                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                        <MenuItem onClick={handleClose}>List</MenuItem>
                                                        <MenuItem onClick={handleClose}>Map</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        );
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12} md={3} >
                    <ColorCard title="Customers" subtitle="Active Customers" value="213" icon="img/icon/Icon_customer.svg" pval="20%" styles={classes.color1} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <ColorCard title="Countries" subtitle="Deployed Countries" value="23" icon="img/icon/Icon_countries.svg" pval="10%" styles={classes.color2} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <ColorCard title="Devices by Customer" subtitle="Deployed Devices" value="3187" icon="img/icon/Icon_devices.svg" pval="35%" styles={classes.color3} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <ColorCard title="Devices by Country" subtitle="Deployed Devices" value="3187" icon="img/icon/Icon_devices.svg" pval="25%" styles={classes.color4} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIDataTable title={<CardHeader style={{marginLeft: '-24px'}} title="Application Performance" />} data={data} columns={columns} options={options} className={classes.table} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <Card variant="outlined">
                        <CardHeader title="Devices by Location" />
                        <CardContent>
                            <Map />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardHeader title="Screening vs Biochip Lifetime" />
                        <CardContent>
                            {/* <LineChart data={Line.data} options={Line.options} width={600} height={300} /> */}
                            <LineGraph/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Card variant="outlined">
                        <CardHeader title="Application vs Screening Data" />
                        <CardContent>
                            {/* <BarChart data={Bar.data} options={Bar.options} width={600} height={300} /> */}
                            <BarGraph />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )


}


export default Administratorv2;