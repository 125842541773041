import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph } from "../../graph";
import history from '../../utils/history';
import { post } from '../../utils/request';
import { Store } from "../../store/Store";



const useStyles = makeStyles((theme) => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
        //backgroundColor: theme.palette.secondary.main,
    },
    form: {
        //width: '100%', // Fix IE 11 issue.
        //marginTop: theme.spacing(1),
        //marginRight: 0
        maxWidth: '500px',
        padding: '5%'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    fhight: {
        paddingTop: '5%',
        maxHeight: '100%',
        height: '100%'
    },
    fwidth: {
        maxWidth: '100%',
        width: '100%',
        maxHeight: '100%'
    },
    logo: {
        margin: '250px 0px 10px',
        [theme.breakpoints.down("md")]: {
            margin: '150px 0px 10px'
        },
        [theme.breakpoints.down("sm")]: {
            margin: '100px 0px 10px'
        }
    },
    logoal: {
        margin: '150px 0px 0px',
        [theme.breakpoints.down("md")]: {
            margin: '100px 0px 0px'
        },
        [theme.breakpoints.down("sm")]: {
            margin: '60px 0px 0px'
        }
    },
    btnflat: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#343434',
        cursor: 'pointer',
        transition: 'background-color .2s',
        border: 'black',
        marginTop: '0px'
    },
    mainContent: {
        maxHeight: '100%',
        height: '100%',
        marginBottom: 'initial'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative"
    },
    buttonProgress: {
        //color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12
    },
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "-25px 0 0 - 25px"
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '54ch',
    },
}));

export default function LogIn() {

    const { state, dispatch } = React.useContext(Store);
    const [loading, setLoading] = React.useState(false);
    const classes = useStyles();
    const isAuthenticated = useIsAuthenticated();
    //let history = useHistory();

    const [values, setValues] = React.useState(false);

    const changeVisibility = () => {
        setValues(!values)
    }


    const [emval, setEmValues] = React.useState("");

    const emvalchng = (event) => {
        setEmValues(event.target.value)
    }

    const [pasval, setPasValues] = React.useState("");

    const pasvalchng = (event) => {
        setPasValues(event.target.value)
    }

    const RequestProfileData = () => {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => {
                console.log(response);
                //history.push("/home")
                post('/loginUsr', { "email": response.mail }).then((response) => {
                    console.log(response);
                    if (response.data.result.message === "Authenticated") {
                        dispatch({
                            type: "USER_DATA",
                            payload: response.data.result
                        });
                        setLoading(false);
                        history.push("/home");
                        //history("/home");
                    }
                }).catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            });
        });
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const [open, setOpen] = React.useState(false);

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const handleCloses = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const lgn = () => {
        setLoading(true);
        // axios.post('http://localhost:8023/loginUsr', { "email": emval, "password": pasval }).then((response) => {
        //     console.log(response);
        //     if (response.data.result.message === "Authenticated") {
        //         dispatch({
        //             type: "USER_DATA",
        //             payload: response.data.result
        //         });
        //         history.push("/home");
        //     }
        // });
        post('/loginUsr', { "email": emval, "password": pasval }).then((response) => {
            console.log(response);
            if (response.data.result.message === "Authenticated") {
                dispatch({
                    type: "USER_DATA",
                    payload: response.data.result
                });
                setLoading(false);
                history.push("/home");
            } else {
                setLoading(false);
                setOpen(true);
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const { instance, accounts } = useMsal();

    const handleLogin = (loginType) => {
        console.log("Entered")
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).then(res => { console.log(res) }).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

    const [password, setPassword] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setPassword({ ...password, showPassword: !password.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleEnter = e => {
        if (e.keyCode === 13) {
            lgn();
        }
      };


    function notLoged() {
        return (
            <Grid container direction="row">
                <Box
                    component={Grid}
                    className={classes.fhight}
                    item
                    sm={8}
                    display={{ xs: "none", sm: "block" }}
                >
                    <img className={classes.fwidth} src="img/welcome.svg"></img>
                </Box>
                <Grid item xs={12} sm={4} justify="center" alignItems="center" alignContent="center">
                    <Collapse in={!values} timeout="auto" >
                        <Box component="div" className={classes.paper}>
                            <div className={classes.logo}>
                                <img src="img/login-logo.svg" alt="Logo" style={{ width: '320px', marginRight: '18px' }} />
                            </div>
                            {/* <form> */}
                            <button className={classes.btnflat} onClick={() => handleLogin("redirect")}>
                                <img src="img/ms-signin.svg" alt="Sign In with Microsoft" />
                            </button>
                            {/* </form> */}
                            {/* <form action="http://localhost:8045/msal4jsample/secure/aad">
                                <button className={classes.btnflat} type="submit">
                                    <img src="https://tulip.onebacardi.com/static/img/ms-signin.svg" alt="Sign In with Microsoft" />
                                </button>
                            </form> */}
                            <div style={{ marginTop: '12px' }}><img src="img/or.png" alt="OR Imag" /></div>
                            <Button onClick={() => changeVisibility()} style={{ marginTop: '4px' }} >Login with email</Button>
                        </Box>
                    </Collapse>
                    <Collapse in={values} timeout="auto">
                        <Box component="div" className={classes.paper}>
                            <div className={classes.logoal}>
                                <img src="img/login-logo.svg" alt="Logo" style={{ width: '320px' }} />
                            </div>
                            <form className={classes.form} noValidate>
                                {/* <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Email Address</InputLabel>
                                    <OutlinedInput
                                        id="email"
                                        type='email'
                                        value={emval}
                                        onChange={emvalchng}
                                        labelWidth={102}
                                        required
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                </FormControl> */}
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={emval}
                                    onChange={emvalchng}
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={password.showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    value={pasval}
                                    onChange={pasvalchng}
                                    onKeyDown={handleEnter}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                                {/* <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={password.showPassword ? 'text' : 'password'}
                                        value={pasval}
                                        onChange={pasvalchng}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                        required
                                    />
                                </FormControl> */}
                                <div className={classes.wrapper}>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.submit}
                                        onClick={() => lgn()}
                                    >
                                        Sign In
                                    </Button>
                                    {loading && (
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    )}
                                </div>
                                <Grid container>
                                    <Grid item xs>
                                        <Button onClick={() => changeVisibility()}><ArrowBackIosSharpIcon fontSize="small" />&nbsp;&nbsp;Back</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Collapse>
                </Grid>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleCloses}>
                    <Alert onClose={handleCloses} severity="error">
                        Invalid Credentials
                    </Alert>
                </Snackbar>
            </Grid>
        )
    }


    function LoggedIn() {
        return (
            <div className={classes.root}>
                {/* <h5 >Welcome {accounts[0].name}</h5> */}
                <CircularProgress />
                {RequestProfileData()}
            </div>
        )
    }


    return (
        <div className={classes.mainContent}>
            <CssBaseline />
            { isAuthenticated ? LoggedIn() : notLoged()}
        </div>
    );
}