import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StoreProvider } from "./store/Store";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

// ReactDOM.render(
//   <StoreProvider>
//     <App />
//   </StoreProvider>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

