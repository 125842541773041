import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Store } from "../../store/Store";

export default function AddRole() {

    const { state, dispatch } = React.useContext(Store);

    const [lstate, setLstate] = React.useState({
        role_name: "", module_id: "",

        sts: "", dalog: false,
        role_namespn: "", module_idspn: "",
        err: {
            role_name: false, module_id: false
        }

    });

    
    const handleClose = (event, reason) => {
        console.log(reason);
        if (reason === 'clickaway') {
            return;
        }

        setLstate({   ...lstate,
            dalog: !lstate.dalog
        });
    };
    const [tstate, setTstate] = React.useState({
        acorOpn: false,
        rolacor:true,
        rolresponse:{},
        checked: [],
        alltran:[],
        snkbrdta: { sts: "", msg: "" }
    });
    const handlechange = (event) => {
        console.log("Before: ", lstate);

        
        if (lstate.role_name === null || lstate.role_name === '') {

            setLstate({   ...lstate,
                role_namespn: "Please fill Role Name", err: { role_name: true } });
        } else if (lstate.module_id === '') {

            setLstate({  ...lstate,
                 module_idspn: "Please select module", err: { module_id: true } });
        } else {
            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/RolewithModule', lstate).then((response) => {
                console.log("After: ", response);

               
                setTstate({  ...tstate,rolresponse:response.data.result,
                    alltran:response.data.result.translist,  acorOpn: true,rolacor:false,
                    snkbrdta: { sts: "success", msg: "Saved Successfully" }
                });
                handleClose();
            })
           

        }

    }

 
    const handleToggle = (value) => () => {
        console.log("Before: ", value.transaction_id);
        const currentIndex = tstate.checked.indexOf(value.transaction_id);
        const newChecked = [...tstate.checked];

        if (currentIndex === -1) {
            console.log("if: ", value.transaction_id);
            newChecked.push(value.transaction_id);
        } else {
            console.log("else: ", value.transaction_id);
            newChecked.splice(currentIndex, 1);
        }

        // setChecked(newChecked);
        setTstate({  ...tstate,
            checked: newChecked
        });
        console.log("tstate: ", tstate.checked);
    };


    const assignTransaction = (event) => {
        let dta={ 'role_id': tstate.rolresponse.roleid, 'module_id': tstate.rolresponse.module_id ,
        'transaction_id': tstate.checked};
        axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/transactionAccess', dta).then((response) => {
                console.log("After: ", response);
                setTstate({...tstate,
                    acorOpn: false,
                    rolacor:true,
                    rolresponse:{},
                    checked: [],
                    alltran:[],
                    snkbrdta: { sts: "success", msg: "Saved Successfully" }
                });
                setLstate({   ...lstate,
                    role_name: "", module_id: "",

                    sts: "", dalog: false,
                    role_namespn: "", module_idspn: "",
                    err: {
                        role_name: false, module_id: false
                    }
            
                });
            })
    }
    return (
        <div>
            {/* <Container maxWidth="md"> */}
            <Snackbar open={lstate.dalog} autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleClose} >
                <Alert severity={lstate.sts} onClose={handleClose}

                    variant="filled">
                    {lstate.msg}
                </Alert>
            </Snackbar>
            <Card >
                <CardHeader

                    title="Add Role"
                >

                </CardHeader>

                <CardContent>
                    <Grid container spacing={1} direction="column">
                        <Grid item xs={12}>
                            <Accordion   expanded={tstate.rolacor}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <div >
                                        <Typography >Role</Typography>
                                    </div>

                                </AccordionSummary>
                                <AccordionDetails >
                                    {/* <Container fixed> */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3} >
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        error={lstate.err.role_name}
                                                        helperText={lstate.role_namespn}
                                                        type="text"
                                                        label="Role Name"
                                                        fullWidth
                                                        name="role_name"
                                                        variant="outlined"
                                                        value={lstate.role_name}

                                                        onChange={(event) =>
                                                            setLstate({
                                                                ...lstate,
                                                                [event.target.name]: event.target.value,
                                                                role_namespn: "",
                                                                err: { role_name: false }
                                                            })
                                                        }
                                                        autoFocus
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        name="module_id"
                                                        select
                                                        label="Module"
                                                        variant="outlined"
                                                        value={lstate.module_id}
                                                        error={lstate.err.module_id}
                                                        helperText={lstate.module_idspn}
                                                        onChange={(event) =>
                                                            setLstate({
                                                                ...lstate,
                                                                [event.target.name]: event.target.value,
                                                                module_idspn: "",
                                                                err: { module_id: false }
                                                            })
                                                        }
                                                        fullWidth
                                                    >
                                                        {state.user.Module.map((option) => (
                                                            <MenuItem key={option.module_id} value={option.module_id}>
                                                                {option.module_name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={8} justify="flex-end">
                                                <Grid item  >
                                                    <Button variant="contained" color="primary"
                                                        onClick={handlechange}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                    {/* </Container> */}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion 
                             expanded={tstate.acorOpn}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <div >
                                        <Typography >Transactions</Typography>
                                    </div>

                                </AccordionSummary>
                                <AccordionDetails >
                                    <Container fixed>
                                        <FormGroup row>
                                            {tstate.alltran.map((value) => {
                                                const labelId = `checkbox-list-label-${value}`;
                                                return (
                                                    <FormControlLabel
                                                        onClick={handleToggle(value)}
                                                        control={<Checkbox
                                                            checked={tstate.checked.indexOf(value.transaction_id) !== -1}
                                                            name="checkedA" />}
                                                        label={value.transaction_name}
                                                    />
                                                );
                                            })}
                                        </FormGroup>
                                        <Grid container spacing={8} justify="flex-end">
                                                <Grid item  >
                                                    <Button variant="contained" color="primary"
                                                        onClick={assignTransaction}
                                                    // type="submit"
                                                    >
                                                        Assign
                                                        </Button>
                                                </Grid>
                                            </Grid>
                                    </Container>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>
            {/* </Container> */}
        </div>
    )
}
