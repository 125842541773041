// import React from "react";

// export const Store = React.createContext();

// const initialState = {
//   user: [],
//   menu: []
// };

// function reducer(state, action) {
//   switch (action.type) {
//     case "USER_DATA":
//       return { ...state, user: action.payload, menu: Gettransacdata(action.payload) };
//     default:
//       return state;
//   }
// }

// export function StoreProvider(props) {
//   const [state, dispatch] = React.useReducer(reducer, initialState);
//   const value = { state, dispatch };
//   return <Store.Provider value={value}>{props.children}</Store.Provider>;
// }

// function Gettransacdata(data) {
//   let rslt = data;
//   let Menu = [];
//   rslt.ModuleMenu.forEach(msgTemplate => {
//       var itemsdta=[]
//       let mdnm=msgTemplate.module_name;
//       rslt[mdnm].forEach(msgTemplate1 => {
//           var subitemsDta=[]
//           let rlnm=msgTemplate1.rolename;
//           rslt[rlnm].forEach(msgTemplate2 => {
//               var subitemsjsn={ 
//                           id: msgTemplate2.transaction_id,
//                           name: msgTemplate2.transaction_name
//                       }
//               subitemsDta.push(subitemsjsn);
//           })
//           var itemjsn={
//               id: msgTemplate1.roleid,
//               name: msgTemplate1.rolename,
//               subitems: subitemsDta
//           }
//           itemsdta.push(itemjsn);
//       })
//       var dts = {
//           id: msgTemplate.module_id,
//           title: msgTemplate.module_name,
//           items: itemsdta
//       }
//       Menu.push(dts);
//   });
//   return Menu;
// }

// // function Gettransacdata(data) {
// //   let rslt = data;
// //   let Menu = [];
// //   rslt.ModuleMenu.map(msgTemplate => {
// //       var itemsdta=[]
// //       let mdnm=msgTemplate.module_name;
// //       rslt[mdnm].map(msgTemplate1 => {
// //           var subitemsDta=[]
// //           let rlnm=msgTemplate1.rolename;
// //           rslt[rlnm].map(msgTemplate2 => {
// //               var subitemsjsn={ 
// //                           id: msgTemplate2.transaction_id,
// //                           name: msgTemplate2.transaction_name
// //                       }
// //               subitemsDta.push(subitemsjsn);
// //           })
// //           var itemjsn={
// //               id: msgTemplate1.roleid,
// //               name: msgTemplate1.rolename,
// //               subitems: subitemsDta
// //           }
// //           itemsdta.push(itemjsn);
// //       })
// //       var dts = {
// //           id: msgTemplate.module_id,
// //           title: msgTemplate.module_name,
// //           items: itemsdta
// //       }
// //       Menu.push(dts);
// //   });
// //   return Menu;
// // }


import React from "react";

export const Store = React.createContext();

const initialState = {
  user: [],
  menu: []
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_DATA":
      return { ...state, user: action.payload, menu: Gettransacdata(action.payload) };
    case "USER_LOGOUT":
      console.log("entered");
      return { ...state, user: [], menu: action.payload };
    default:
      return state;
  }
}

const localState = JSON.parse(localStorage.getItem("info"));

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, localState || initialState);
  const value = { state, dispatch };
  React.useEffect(() => {
    localStorage.setItem("info", JSON.stringify(state));
  }, [state]);
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

function Gettransacdata(data) {
  let rslt = data;
  let Menu = [];
  let d1 = {
    id: "MOD00000",
    title: "Dashboard",
    items: [
      {
        id: "ROL00000",
        name: "SmellCells",
        subitems:[
          {id: "administrator-v1",name: "Administrator-v1"},{id: "administrator-v2",name: "Administrator-v2"},{id: "businessuser",name: "Business User"},{id: "support",name: "Support User"}
        ]
      },
      {
        id: "ROL00009",
        name: "Customer",
        subitems:[
          {id: "buser",name: "Business User"},{id: "suser",name: "Support User"}
        ]
      }
    ]
  }
  Menu.push(d1);
  rslt.ModuleMenu.forEach(msgTemplate => {
    var itemsdta = []
    let mdnm = msgTemplate.module_name;
    rslt[mdnm].forEach(msgTemplate1 => {
      var subitemsDta = []
      let rlnm = msgTemplate1.rolename;
      rslt[rlnm].forEach(msgTemplate2 => {
        var subitemsjsn = {
          id: msgTemplate2.transaction_id,
          name: msgTemplate2.transaction_name
        }
        subitemsDta.push(subitemsjsn);
      })
      var itemjsn = {
        id: msgTemplate1.roleid,
        name: msgTemplate1.rolename,
        subitems: subitemsDta
      }
      itemsdta.push(itemjsn);
    })
    var dts = {
      id: msgTemplate.module_id,
      title: msgTemplate.module_name,
      items: itemsdta
    }
    Menu.push(dts);
  });
  return Menu;
}
