import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Platform
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(14),
        height: theme.spacing(14),
        //backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginRight: 0
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    fhight: {
        maxHeight: '100%',
        height: '100%'
    },
    fwidth: {
        maxWidth: '100%',
        width: '100%',
        maxHeight: '100%'
    },
    logo: {
        margin: '200px 0px 10px'
    },
    btnflat: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#343434',
        cursor: 'pointer',
        transition: 'background-color .2s',
        border: 'black'
    }
}));

export default function SignIn() {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs" >
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} src="/logo.png"></Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            {/* <Grid container>
                <Grid item xs={6} className={classes.fhight}>
                    <img className={classes.fwidth} src="https://tulip.onebacardi.com/static/images/welcome.svg"></img>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.paper}>
                        <div className={classes.logo}>
                            <img src="/logo.png" alt="Logo" style={{ width: '200px' }} />
                        </div>
                        <button className={classes.btnflat}>
                            <img src="https://tulip.onebacardi.com/static/img/ms-signin.svg" alt="Sign In with Microsoft" />
                        </button>
                        <div style={{marginTop:'13px'}}>OR</div>
                        <Button>Login with non-microsoft account</Button>
                    </div>
                </Grid>
            </Grid> */}
        </Container>
    );
}