import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Store } from "../../store/Store";

export default function AddRole() {

    const { state, dispatch } = React.useContext(Store);

    const [pstate, setPstate] = React.useState({
        catdta: []
    });

    const [lstate, setLstate] = React.useState({
        model: false,
        dalog: false,
        rows: [],
        serresp: [],
        opn: false,
        chkbx: {},
        module_id: ''
      
    });
    const [tstate, setTstate] = React.useState({
        catJsnDta: {},
        model: false,
        dalog: false,
        rasgnMod: false,
        rasgnRol: false,
        rasgnTran: false,
         opn: false,
        checkedMod: [],
        checkedRol: [],
        allRol: [],
        checkedTran: [],
        allmod:state.user.Module,
        allTran: [],
        chkdModresp: [],
        chkdRolresp: [],
        chkdtrnsresp: [],
        fnamespn: "", lnamespn: "",
        emailspn: '', phonespn: '', isActivespn: '',
        snkbrdta: { sts: "", msg: "" },
        err: {
            fname: false, lname: '',
            email: '', phone: '', isActive: ''
        }
    })
    React.useEffect(() => {
        axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/userList', {}).then((response) => {
            // event.preventDefault();
            //console.log(response);
            setPstate({ ...pstate, catdta: response.data.result });

        });
    }, []);


    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                filterType: 'multiselect',
                sort: true,
                draggable: true,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                filterType: 'multiselect',
                sort: true,
                draggable: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>{pstate.catdta[tableMeta.rowIndex].fname} {pstate.catdta[tableMeta.rowIndex].lname}</div>
                    )
                }
            }
        }
        ,
        {
            name: "usrname",
            label: "Username",
            options: {
                filter: true,
                sort: false,
                draggable: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>{"Email:- " + pstate.catdta[tableMeta.rowIndex].email}</div>
                    )
                }
            },
            valueGetter: (params) =>
                `<button>amit</button>`,

        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: true,
                sort: false,
                draggable: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            id={tableMeta.rowData[0]}
                            aria-label="add"
                            onClick={(event) => handlechange(event)}
                            value={tableMeta.rowIndex}
                        >
                            <VisibilityIcon
                            />
                        </IconButton>
                    )
                }
            },


        },

    ];

    const options = {
        // filter: true,
        //   filterType: 'dropdown',
        responsive: 'vertical',
        sortOrder: {
            name: 'id',
            direction: 'asc'
        },
        draggableColumns: {
            enabled: true
        },
        selectableRows: false
    };
    const handleTranToggle = (value) => () => {
        const currentIndex = tstate.checkedTran.indexOf(value.transaction_id);
        const newChecked = [...tstate.checkedTran];
        //console.log(lstate.checked);
        if (currentIndex === -1) {
            newChecked.push(value.transaction_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // setChecked(newChecked);
        setTstate({
            ...tstate,
            checkedTran: newChecked
        });
        //console.log(lstate.checked);
    };

    const handleModToggle = (value) => () => {
        const currentIndex = tstate.checkedMod.indexOf(value.module_id);
        const newChecked = [...tstate.checkedMod];
        // //console.log(lstate.checked);
        if (currentIndex === -1) {
            newChecked.push(value.module_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // setChecked(newChecked);
        setTstate({
            ...tstate,
            checkedMod: newChecked
        });
        // //console.log(lstate.checked);
    };

    const handleRolToggle = (value) => () => {
        const currentIndex = tstate.checkedRol.indexOf(value.roleid);
        const newChecked = [...tstate.checkedRol];
        // //console.log(lstate.checked);
        if (currentIndex === -1) {
            newChecked.push(value.roleid);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // setChecked(newChecked);
        setTstate({
            ...tstate,
            checkedRol: newChecked
        });
        // //console.log(lstate.checked);
    };


    const handlechange = (event) => {
        let dta = pstate.catdta[event.currentTarget.value];
        dta = Object.assign({
            rwindx: event.currentTarget.value
        }, dta);
        console.log(dta);
        // setLstate({ ...lstate, catJsnDta: dta });
        // handleClose();
        axios.get('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/usrAllAcsByActid/' + dta.id).then((response) => {
            console.log(response);
            let chkMd = [];
            {
                response.data.result.ModuleMenu.map((value) => {
                    chkMd.push(value.module_id)

                })
            }
            let chkRl = [];
            {
                response.data.result.Roles.map((value) => {
                    chkRl.push(value.roleid)

                })
            }
            
            console.log(chkMd);
            console.log(chkRl);
            setTstate({...tstate,
                catJsnDta: dta,
                checkedMod: chkMd,
                checkedRol: chkRl,
                chkdModresp: response.data.result.ModuleMenu,
                chkdRolresp: response.data.result.Roles
            })
            // setLstate({
            //     ...lstate,
            //     catJsnDta: dta,
            //     //  trnsresp: response.data.result[0].allTranslist,
            //     checkedMod: chkMd,
            //     checkedRol: chkRl,
            //     chkdModresp: response.data.result.ModuleMenu,
            //     chkdRolresp: response.data.result.Roles
            // });
           
            handleClose();
        });

    }

    const handleClose = () => {
        setLstate({
            ...lstate,
            model: !lstate.model
        });
       
    };


    const handleSubmit = () => {
        setTstate({ ...tstate, catspn: "", profspn: "" });
        if (tstate.catJsnDta.rolename === null || tstate.catJsnDta.rolename === '') {

            setTstate({ ...tstate, catspn: "Name is Required", err: { rolename: true } });
        } else {
            //console.log(tstate.catJsnDta);
            let tst = tstate.catJsnDta;
            // //console.log(tst.amt.props.value);

            let dta = pstate.catdta;
            var mdData = tst
            //console.log(mdData);
            axios.put('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/Account', tst).then((response) => {
                //console.log(response);
                if (response.data.error !== true) {
                    dta[tst.rwindx] = tst;
                    //console.log(dta);
                    setPstate({
                        ...pstate,
                        catdta: dta
                    });
                    setTstate({ ...tstate,
                        snkbrdta: { sts: "success", msg: "Account Id: " + [tstate.catJsnDta.id] + ", " + response.data.message }
                    });
                    handleClose();
                    handleCloseSnk();

                } else {
                     setTstate({ ...tstate,
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    handleCloseSnk();
                }

            });
        }
    }
    const handleReAsignMod = () => {

        if (tstate.checkedMod.length < 1 ||
            tstate.checkedMod.length === tstate.chkdModresp.length) {
            // alert(lstate.chkdtrnsresp.length)
            setTstate({
                ...tstate,
                snkbrdta: { sts: "error", msg: "Please check atleast one Transaction" }
            });
        } else {
            let tst = tstate.catJsnDta;
            //console.log(tst);
            var modjsn = {
                'acntid': tst.id,
                'modid': tstate.checkedMod
            }
            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/updateModAccess', modjsn).then((response) => {
                //console.log(response);
                if (response.data.error !== true) {
                    setTstate({
                        ...tstate, rasgnMod: true,
                        snkbrdta: { sts: "success", msg: "Updated Successfully" }
                    });
                    handleClose();
                    handleCloseSnk();

                } else {
                    setTstate({
                        ...tstate,
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    handleCloseSnk();
                }

            });
        }
    }

    const handleAssignRolefn = () => {

        if (tstate.checkedRol.length < 1 ||
            tstate.checkedRol.length === tstate.chkdRolresp.length) {
            // alert(lstate.chkdtrnsresp.length)
            setTstate({
                ...tstate,
                snkbrdta: { sts: "error", msg: "Please check atleast one Transaction" }
            });
        } else {
            let tst = tstate.catJsnDta;
            //console.log(tst);
            var modjsn = {
                'acntid': tst.id,
                'rolid': tstate.checkedRol
            }
            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/updateUsrRolAccess', modjsn).then((response) => {
                //console.log(response);
                if (response.data.error !== true) {
                    setTstate({
                        ...tstate, rasgnRol: true,
                        snkbrdta: { sts: "success", msg: "Updated Successfully" }
                    });
                    handleClose();
                    handleCloseSnk();

                } else {
                    setTstate({
                        ...tstate,
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    handleCloseSnk();
                }

            });
        }
    }

    const handleAssignTranfn = () => {

        if (tstate.checkedTran.length < 1 ||
            tstate.checkedTran.length === tstate.chkdtrnsresp.length) {
            // alert(lstate.chkdtrnsresp.length)
            setTstate({
                ...tstate,
                snkbrdta: { sts: "error", msg: "Please check atleast one Transaction" }
            });
        } else {
            let tst = tstate.catJsnDta;
            //console.log(tst);
            var modjsn = {
                'acntid': tst.id,
                'transid': tstate.checkedTran
            }
            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/updateUsrTranAccess', modjsn).then((response) => {
                //console.log(response);
                if (response.data.error !== true) {
                    setTstate({
                        ...tstate, rasgnTran: true,
                        snkbrdta: { sts: "success", msg: "Updated Successfully" }
                    });
                    handleClose();
                    handleCloseSnk();

                } else {
                    setTstate({
                        ...tstate,
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    handleCloseSnk();
                }

            });
        }
    }

    const getModAcsVlid = () => {
        //  history.push(`/catagory`);
        //console.log("Function call")
        console.log(tstate.chkdModresp)
        if (tstate.rasgnMod !== true) {
            let chkMd = [];
            {
                tstate.chkdModresp.map((value) => {
                    chkMd.push(value.module_id)

                })
            }
            setTstate({
                ...tstate,
                checkedMod: chkMd
            });
        }
    }
    const getRolAcessDta = () => {
        //  history.push(`/catagory`);
        // console.log("Function call")
        let data = {};
        if (tstate.rasgnMod !== true) {
            console.log("Function call")
            let chkMd = [];
            {
                tstate.chkdModresp.map((value) => {
                    chkMd.push(value.module_id)

                })
            }
            setTstate({
                ...tstate,
                checkedMod: chkMd
            });
            data = { 'modid': chkMd, "usrid": tstate.catJsnDta.id };
        } else {
            data = { 'modid': tstate.checkedMod, "usrid": tstate.catJsnDta.id };
        }

        axios.post("https://api.kiratitsolutions.net:8443/bdsUsrmgmt/getRolesByModid", data).then((response) => {
            console.log(response)
            setTstate({
                ...tstate,
                checkedRol: response.data.result.acssRoles,
                chkdRolresp: response.data.result.acssRoles,
                allRol: response.data.result.Roles
            });
        });
    };

    const getTransAcessDta = () => {
        //  history.push(`/catagory`);
        let data = {};
        if (tstate.rasgnRol !== true) {
            setTstate({
                ...tstate,
                checkedRol: tstate.chkdRolresp
            });
            data = { 'rolid': tstate.chkdRolresp, "usrid": tstate.catJsnDta.id };
        } else {
            data = { 'rolid': tstate.checkedRol, "usrid": tstate.catJsnDta.id };
        }
        // var data = { 'rolid': tstate.checkedRol, "usrid": tstate.catJsnDta.id };
        axios.post("https://api.kiratitsolutions.net:8443/bdsUsrmgmt/getTransByRol", data).then((response) => {
            //console.log(response)
            setTstate({
                ...tstate,
                checkedTran: response.data.result.acssTrans,
                chkdtrnsresp: response.data.result.acssTrans,
                allTran: response.data.result.Trans
            });
        });
    };

    const handleCloseSnk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setLstate({
            ...lstate,
            dalog: !lstate.dalog
        });
    };


    return (
        <div>
            {/* <Container fixed> */}
            <Snackbar open={lstate.dalog} autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleCloseSnk} >
                <Alert severity={tstate.snkbrdta.sts} onClose={handleCloseSnk}

                    variant="filled">
                    {tstate.snkbrdta.msg}
                </Alert>
            </Snackbar>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"
                fullWidth={true}
                fullWidth={true}
                maxWidth={"md"}
                open={lstate.model}>

                <Card >
                    <CardHeader

                        title="User Profile Details"
                    >

                    </CardHeader>

                    <CardContent>
                        {/* <form> */}

                        <Grid container spacing={1} direction="column">
                            <Grid item xs={12}>
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header"
                                    >
                                        <div >
                                            <Typography >Account Details</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Container fixed>
                                            <Grid container spacing={3}>


                                                <Grid item xs={12} >
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                error={tstate.err.fname}
                                                                helperText={tstate.fnamespn}
                                                                type="text"
                                                                label="First Name"
                                                                fullWidth
                                                                name="fname"
                                                                variant="outlined"
                                                                value={tstate.catJsnDta.fname}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                autoFocus
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                error={tstate.err.lname}
                                                                helperText={tstate.lnamespn}
                                                                type="text"
                                                                label="Last Name"
                                                                fullWidth
                                                                name="lname"
                                                                variant="outlined"
                                                                value={tstate.catJsnDta.lname}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                autoFocus
                                                                required
                                                            />
                                                        </Grid>




                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} >
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                error={tstate.err.email}
                                                                helperText={tstate.emailspn}
                                                                type="text"
                                                                label="Email"
                                                                fullWidth
                                                                name="email"
                                                                variant="outlined"
                                                                value={tstate.catJsnDta.email}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                autoFocus
                                                                required
                                                            />

                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                error={tstate.err.phone}
                                                                helperText={tstate.phonespn}
                                                                type="text"
                                                                label="Phone"
                                                                fullWidth
                                                                name="phone"
                                                                variant="outlined"
                                                                value={tstate.catJsnDta.phone}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                autoFocus
                                                                required
                                                            />
                                                        </Grid>




                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} >
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={6}>
                                                            <FormControl variant="outlined" size="large" style={{ minWidth: 390 }}>
                                                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="isActive"
                                                                    name="isActive"
                                                                    value={tstate.catJsnDta.isActive}
                                                                    onChange={(event) =>
                                                                        setTstate(prevState => ({
                                                                            ...tstate,
                                                                            catJsnDta: {
                                                                                ...prevState.catJsnDta,
                                                                                isActive: event.target.value
                                                                            },
                                                                            isActivespn: "",
                                                                            err: { isActive: false }
                                                                        }))
                                                                    }
                                                                    label="Status"
                                                                    autoFocus
                                                                    required
                                                                >
                                                                    <MenuItem value={1}>Active</MenuItem>
                                                                    <MenuItem value={0}>In Active</MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid container spacing={8} justify="flex-end">
                                                <Grid item  >
                                                    <Button variant="contained" color="primary"
                                                        onClick={handleSubmit}
                                                    // type="submit"
                                                    >
                                                        Update
                                </Button>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion onChange={(e, expanded) => {
                                    if (expanded) {
                                        getModAcsVlid()
                                    }
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1c-content"
                                        id="modacord"

                                    >
                                        <div >
                                            <Typography >Modules</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Container fixed>
                                            <FormGroup row>
                                                {tstate.allmod.map((value) => {
                                                    const labelId = `checkbox-list-label-${value}`;
                                                    return (
                                                        <FormControlLabel
                                                            onClick={handleModToggle(value)}
                                                            control={<Checkbox
                                                                checked={tstate.checkedMod.indexOf(value.module_id) !== -1}
                                                                name="checkedA" />}
                                                            label={value.module_name}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                            <Grid container spacing={8} justify="flex-end">
                                                <Grid item  >
                                                    <Button variant="contained" color="primary"
                                                        onClick={handleReAsignMod}
                                                    // type="submit"
                                                    >
                                                        Re-Assign
                                                        </Button>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion onChange={(e, expanded) => {
                                    if (expanded) {
                                        getRolAcessDta()
                                    }
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1c-content"
                                        id="rolacord"
                                    >
                                        <div >
                                            <Typography >Roles</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Container fixed>
                                            <FormGroup row>
                                                {tstate.allRol.map((value) => {
                                                    const labelId = `checkbox-list-label-${value}`;
                                                    return (
                                                        <FormControlLabel
                                                            onClick={handleRolToggle(value)}
                                                            control={<Checkbox
                                                                checked={tstate.checkedRol.indexOf(value.roleid) !== -1}
                                                                name="checkedA" />}
                                                            label={value.rolename}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                            <Grid container spacing={8} justify="flex-end">
                                                <Grid item  >
                                                    <Button variant="contained" color="primary"
                                                        onClick={handleAssignRolefn}
                                                    // type="submit"
                                                    >
                                                        Re-Assign
                                                        </Button>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion onChange={(e, expanded) => {
                                    if (expanded) {
                                        getTransAcessDta()
                                    }
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1c-content"
                                        id="transacord"
                                    >
                                        <div >
                                            <Typography >Transactions</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Container fixed>
                                            <FormGroup row>
                                                {tstate.allTran.map((value) => {
                                                    const labelId = `checkbox-list-label-${value}`;
                                                    return (
                                                        <FormControlLabel
                                                            onClick={handleTranToggle(value)}
                                                            control={<Checkbox
                                                                checked={tstate.checkedTran.indexOf(value.transaction_id) !== -1}
                                                                name="checkedA" />}
                                                            label={value.transaction_name}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                            <Grid container spacing={8} justify="flex-end">
                                                <Grid item  >
                                                    <Button variant="contained" color="primary"
                                                        onClick={handleAssignTranfn}
                                                    // type="submit"
                                                    >
                                                        Re-Assign
                                                        </Button>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>

                        {/* </form> */}
                    </CardContent>
                </Card>

            </Dialog>

            <MUIDataTable
                title={"Users"}
                data={pstate.catdta}
                columns={columns}
                options={options}
            />
            {/* </Container> */}
        </div>
    )
}