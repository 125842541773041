import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from './Menu';
import { Store } from "../../store/Store";
import { Redirect } from 'react-router-dom';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useIsAuthenticated } from "@azure/msal-react";
import { post } from '../../utils/request';
import { useMsal } from "@azure/msal-react";
import history from '../../utils/history';
//import { useHistory } from "react-router-dom";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        height: "4em",
        [theme.breakpoints.down("md")]: {
            height: "4em"
        },
        [theme.breakpoints.down("xs")]: {
            height: "3.5em"
        }
    },
    logoContainer: {
        padding: 0,
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerHeader: {
        display: 'flex',
        //alignItems: 'center',
        //padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        marginLeft: '12px'
        //justifyContent: 'flex-end',
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    profile: {
        fontSize: 'small',
        fontWeight: 400
    },
    //----------------------------------
    appBar1: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton1: {
        marginRight: 36
    },
    hide: {
        display: "none"
    },
    drawer1: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar1: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    }
}));

function Header(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { state, dispatch } = React.useContext(Store);
    //const [anchorEl, setAnchorEl] = React.useState(null);
    const isAuthenticated = useIsAuthenticated();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    //let history = useHistory();
    const { instance } = useMsal();

    if (state.user.length === 0) {
        return <Redirect to="/login" />;
    }

    // const handleProfileMenuOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const mLogout = () => {
        console.log("mLogout");
        localStorage.removeItem('info');
        instance.logout();
    };
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    const oLogout = (event) => {
        console.log("oLogout")
        //post("/AccountLogout", { "tkn": state.user.Profile.token }).then((response) => {
        //console.log(response);
        //if (response.data.message === "Success") {
        //localStorage.removeItem('info');
        //history.push("/login");
        dispatch({
            type: "USER_LOGOUT",
            payload: null
        });
        localStorage.removeItem('info');
        sleep(2000).then(() => {
            //history.push("/login");
            //window.location.reload();
        })

        //return <Redirect to="/login" />;
        //}
        // }).catch((error) => {
        //     console.log(error);
        // });
    };

    const menuId = 'primary-search-account-menu';

    const drawer = (
        <div>
            <div className={classes.drawerHeader}>
                <Grid container wrap="nowrap" direction="row" justify="flex-start" alignItems="center" >
                    <Grid item>
                        <AccountCircle />
                    </Grid>
                    <Grid item xs zeroMinWidth style={{ marginLeft: '10px' }}>
                        <Typography variant="body2" noWrap>{state.user.Profile.email}</Typography>
                    </Grid>
                </Grid>
            </div>
            {/* <div className={classes.profile}>
                <IconButton
                    edge="end"
                    disableFocusRipple={true}
                    disableRipple={true}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                >
                <Typography variant="body2" > {state.user.Profile.email} </Typography>
                </IconButton>
                <IconButton
                    edge="end"
                    aria-label="logout"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <PowerSettingsNewIcon />
                </IconButton>
            </div> */}
            {/* <div className={classes.toolbar} /> */}
            <Divider />
            <Menu menu={state.menu} title={open} />
        </div>
    );

    const drawer1 = (
        <div>
            <div className={classes.drawerHeader}>
                <Grid container wrap="nowrap" direction="row" justify="flex-start" alignItems="center" >
                    <Grid item>
                        <AccountCircle />
                    </Grid>
                    <Grid item xs zeroMinWidth style={{ marginLeft: '10px' }}>
                        <Typography variant="body2" noWrap>{state.user.Profile.email}</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Collapse Menu">
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === "rtl" ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <ChevronLeftIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
            {/* <div className={classes.profile}>
                <IconButton
                    edge="end"
                    disableFocusRipple={true}
                    disableRipple={true}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                >
                <Typography variant="body2" > {state.user.Profile.email} </Typography>
                </IconButton>
                <IconButton
                    edge="end"
                    aria-label="logout"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <PowerSettingsNewIcon />
                </IconButton>
            </div> */}
            {/* <div className={classes.toolbar} /> */}
            <Divider />
            <Menu menu={state.menu} title={open} />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden xsDown implementation="css">
                <AppBar position="fixed" className={clsx(classes.appBar1, { [classes.appBarShift]: open })}>
                    <Toolbar>
                        <Tooltip title="Open Menu">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton1, {
                                    [classes.hide]: open
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Button
                            component={Link}
                            to="/home"
                            disableRipple
                            //onClick={() => history.push("/home")}
                            className={classes.logoContainer}
                        >
                            <img alt="company logo" className={classes.logo} src="img/logo_white.svg" style={{ height: '64px', marginLeft: '-40px' }} />
                        </Button>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <Tooltip title="Notifications">
                                <IconButton aria-label="notifications" color="inherit">
                                    <Badge badgeContent={17} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Logout">
                                <IconButton aria-label="logout" edge="end" color="inherit" onClick={() => { isAuthenticated ? mLogout() : oLogout() }}>
                                    <PowerSettingsNewIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={classes.sectionMobile}>
                            <Tooltip title="Logout">
                                <IconButton aria-label="logout" edge="end" color="inherit" onClick={() => { isAuthenticated ? mLogout() : oLogout() }}>
                                    <PowerSettingsNewIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <Hidden smUp implementation="css">
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Button
                            component={Link}
                            to="/home"
                            disableRipple
                            //onClick={() => history.push("/home")}
                            className={classes.logoContainer}
                        >
                            <img alt="company logo" className={classes.logo} src="img/logo_white.svg" style={{ height: '56px', marginLeft: '-60px' }} />
                        </Button>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <Tooltip title="Notifications">
                                <IconButton aria-label="notifications" color="inherit">
                                    <Badge badgeContent={17} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Logout">
                                <IconButton aria-label="logout" edge="end" color="inherit" onClick={() => { isAuthenticated ? mLogout() : oLogout() }}>
                                    <PowerSettingsNewIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={classes.sectionMobile}>
                            <Tooltip title="Logout">
                                <IconButton aria-label="logout" edge="end" color="inherit" onClick={() => { isAuthenticated ? mLogout() : oLogout() }}>
                                    <PowerSettingsNewIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <nav aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        className={classes.drawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer1, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open
                            })
                        }}
                        open
                    >
                        {drawer1}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}

Header.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Header;
