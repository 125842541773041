import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { BarGraph, LineGraph } from '../Map/Graph';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import Menu from '@material-ui/core/Menu';
import Map from '../Map/Map';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    c1: {
        fontSize: 32,
        fontWeight: 100,
        marginTop: 5,
        marginLeft: 7
    },
    color1: {
        backgroundImage: 'linear-gradient(135deg,#36d1dc 10%,#5b86e5)'
    },
    color2: {
        backgroundImage: 'linear-gradient(135deg,#ee9ae5 10%,#5961f9)'
    },
    color3: {
        //backgroundImage: 'linear-gradient(135deg,#80d0c7 10%,#13547a)'
        backgroundImage: 'linear-gradient(135deg,#80d0c7 10%,#5a8ca9)'
    },
    color4: {
        backgroundImage: 'linear-gradient(135deg,#06beb6 10%,#48b1bf)'
    },
    divider: {
        backgroundColor: 'black',
        height: '1px',
        //backgroundImage: 'linear-gradient(135deg,#36d1dc 10%,#5b86e5)'
    },
    icon: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    }
}));

function Home() {

    const classes = useStyles();

    const ColorCard = (props) => {

        const { title, subtitle, value, icon, pval, styles } = props;

        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        // const handleClose = (event) => {
        //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
        //         return;
        //     }

        //     setOpen(false);
        // };

        function handleListKeyDown(event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            }
        }

        // return focus to the button when we transitioned from !open -> open
        const prevOpen = React.useRef(open);
        React.useEffect(() => {
            if (prevOpen.current === true && open === false) {
                anchorRef.current.focus();
            }

            prevOpen.current = open;
        }, [open]);


        return (
            <Card variant="outlined" className={styles}>
                <CardContent>
                    <Grid container direction="column" >
                        <Grid item >
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle1">
                                            {title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <img src={icon} className={classes.icon} />
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} style={{ marginBottom: '15px' }} gutterBottom />
                        </Grid>
                        <Grid item justify="flex-start" alignItems="flex-start">
                            <Typography className={classes.c1} gutterBottom >
                                {value}
                            </Typography>
                        </Grid>
                        <Grid item justify="flex-start" alignItems="flex-start" style={{ marginTop: '-12px' }} >
                            <Typography variant="caption" style={{ marginLeft: '8px' }}>
                                {subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid container xs direction="row" style={{ marginLeft: '15px', marginTop: '11px' }}>
                                    <Typography variant="subtitle1">
                                        {pval}
                                    </Typography>
                                    <TrendingUpOutlinedIcon />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                    View
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>List</MenuItem>
                                    <MenuItem onClick={handleClose}>Map</MenuItem>
                                </Menu>
                                {/* <Button
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    View
                                </Button>
                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                        <MenuItem onClick={handleClose}>List</MenuItem>
                                                        <MenuItem onClick={handleClose}>Map</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        );
    };

    // const [country, setCountry] = React.useState('USA');

    // const data= {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [{
    //         label: "Applications by Country",
    //         backgroundColor: '#23b7e5',
    //         borderColor: '#23b7e5',
    //         data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    //     }, {
    //         label: "Screening dataset",
    //         backgroundColor: '#5d9cec',
    //         borderColor: '#5d9cec',
    //         data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    //     }]
    // }
    // const data1= {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [{
    //         label: "Applications by Country",
    //         backgroundColor: '#23b7e5',
    //         borderColor: '#23b7e5',
    //         data: [10, 40, 60, 80, 20, 90, 75]
    //     }, {
    //         label: "Screening dataset",
    //         backgroundColor: '#5d9cec',
    //         borderColor: '#5d9cec',
    //         data: [25, 35, 45, 55, 65, 75, 85]
    //     }]
    // }
    // const data2= {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [{
    //         label: "Applications by Country",
    //         backgroundColor: '#23b7e5',
    //         borderColor: '#23b7e5',
    //         data: [25, 55, 45, 55, 65, 75, 85]
    //     }, {
    //         label: "Screening dataset",
    //         backgroundColor: '#5d9cec',
    //         borderColor: '#5d9cec',
    //         data: [10, 20, 70, 80, 20, 40, 70]
    //     }]
    // }
    // const [bardata, setBarData] = React.useState(data);

    // const handleChange = (event) => {
    //     setCountry(event.target.value);
    //     console.log(event.target.value);
    //     if(event.target.value==='Sweden'){
    //         setBarData(data1);
    //     }else if(event.target.value==='Denmark'){
    //         setBarData(data2);
    //     } else {
    //         setBarData(data);
    //     }
    // };

    // const lined1={
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [{
    //         label: 'Screening as service dataset',
    //         backgroundColor: 'rgba(114,102,186,0.2)',
    //         borderColor: 'rgba(114,102,186,1)',
    //         pointBorderColor: '#fff',
    //         data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    //     }, {
    //         label: 'Biochip lifetime dataset',
    //         backgroundColor: 'rgba(35,183,229,0.2)',
    //         borderColor: 'rgba(35,183,229,1)',
    //         pointBorderColor: '#fff',
    //         data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    //     }]
    // };

    // const [linedata, setLineData] = React.useState(lined1);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container spacing={3} justify="flex-start" alignItems="center">
                <Grid item xs={12} md={3} >
                    <ColorCard title="Customers" subtitle="Active Customers" value="213" icon="img/icon/Icon_customer.svg" pval="20%" styles={classes.color1} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <ColorCard title="Countries" subtitle="Deployed Countries" value="23" icon="img/icon/Icon_countries.svg" pval="10%" styles={classes.color2} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <ColorCard title="Devices by Customer" subtitle="Deployed Devices" value="3187" icon="img/icon/Icon_devices.svg" pval="35%" styles={classes.color3} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <ColorCard title="Devices by Country" subtitle="Deployed Devices" value="3187" icon="img/icon/Icon_devices.svg" pval="25%" styles={classes.color4} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardHeader title="Screening vs Biochip Lifetime" />
                        <CardContent>
                            {/* <LineChart data={linedata} options={Line.options} width={600} height={300} /> */}
                            <LineGraph />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Card variant="outlined">
                        <CardHeader title="Application vs Screening Data" />
                        <CardContent>
                            {/* <BarChart data={bardata} options={Bar.options} width={600} height={300} />
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={country}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'USA'}>USA</MenuItem>
                                    <MenuItem value={'Sweden'}>Sweden</MenuItem>
                                    <MenuItem value={'Denmark'}>Denmark</MenuItem>
                                </Select>
                            </FormControl> */}
                            <BarGraph />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card variant="outlined">
                        <CardHeader title="Installations Worldwide" />
                        <CardContent>
                            <Map />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )


}


export default Home;