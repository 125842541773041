import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { Store } from "../../store/Store";

export default function TransactionListTable() {

    const { state, dispatch } = React.useContext(Store);
    const [lstate, setLstate] = React.useState({
        catdta: [],
        model: false,
        dalog: false,
        rows: [],
        serresp: [],
        opn: false,
        chkbx: {},
        // catJsnDta: {},
        // snkbrdta: { sts: "", msg: "" },
        // catspn: "", profspn: "",
        // err: {
        //     module_id: false, proftyp: false
        // }

    });


    const [tstate, setTstate] = React.useState({
        catJsnDta: {}
        ,
        snkbrdta: { sts: "", msg: "" },
        catspn: "", profspn: "",
        err: {
            transaction_name: false, proftyp: false
        }
    });
    const columns = [
        {
            name: "transaction_id",
            label: "ID",
            options: {
                filter: true,
                filterType: 'multiselect',
                sort: true,
                draggable: true,
            }
        },
        {
            name: "transaction_name",
            label: "Transaction Name",
            options: {
                filter: true,
                filterType: 'multiselect',
                sort: true,
                draggable: true,
            }
        }
        ,
        {
            name: "action",
            label: "Action",
            options: {
                filter: true,
                sort: false,
                draggable: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            id={tableMeta.rowData[0]}
                            aria-label="add"
                            // onClick={(event) => handlechange(idy)}
                            onClick={(event) => handlechange(event)}
                            value={tableMeta.rowIndex}
                        >
                            <VisibilityIcon
                            />
                        </IconButton>
                        //        <button onClick={() => window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)}>
                        //   Edit
                        // </button>
                    )
                }
            },


        },

    ];


    const options = {
        // filter: true,
        //   filterType: 'dropdown',
        responsive: 'vertical',
        sortOrder: {
            name: 'id',
            direction: 'asc'
        },
        draggableColumns: {
            enabled: true
        },
        selectableRows: false
    };

    const handlechange = (event) => {
        let dta = lstate.catdta[event.currentTarget.value];
        dta = Object.assign({
            rwindx: event.currentTarget.value
        }, dta);
        console.log(dta);
        setTstate({ ...tstate, catJsnDta: dta });
        console.log(tstate.catJsnDta);
        handleClose();
    }

    const handleSubmit = () => {
        setTstate({ ...tstate, catspn: "", profspn: "" });
        if (tstate.catJsnDta.transaction_name === null || tstate.catJsnDta.transaction_name === '') {

            setTstate({ ...tstate, catspn: "Transaction name is Required", err: { transaction_name: true } });
        } else {
            console.log(tstate.catJsnDta);
            let tst = tstate.catJsnDta;
            // console.log(tst.amt.props.value);

            let dta = lstate.catdta;
            var mdData = tst
            console.log(mdData);
            axios.put('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/transaction', tst).then((response) => {
                console.log(response);
                if (response.data.error !== true) {
                    dta[tst.rwindx] = tst;
                    console.log(dta);
                    setLstate({ ...lstate,
                        catdta: dta
                    });
                    setTstate({ ...tstate,
                        snkbrdta: { sts: "success", msg: "Trasnsaction Id: " + [tstate.catJsnDta.transaction_id] + ", " + response.data.message }
                    });
                    handleClose();
                    handleCloseSnk();

                } else {
                    setTstate({ ...tstate,
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    handleCloseSnk();
                }

            });
        }
    }


    const gettranslist = (event) => {
        axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/transList', { "module_id": event.target.value }).then((response) => {
            // event.preventDefault();
            console.log(response);
            setLstate({ ...lstate, catdta: response.data.result });

        });
    };

  


    const handleClose = () => {
        setLstate({ ...lstate,
            model: !lstate.model
        });
    };

    const handleCloseSnk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setLstate({ ...lstate,
            dalog: !lstate.dalog
        });
    };


    return (
        <div>
            {/* <Container fixed> */}
            <Snackbar open={lstate.dalog} autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleCloseSnk} >
                <Alert severity={tstate.snkbrdta.sts} onClose={handleCloseSnk}

                    variant="filled">
                    {tstate.snkbrdta.msg}
                </Alert>
            </Snackbar>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={lstate.model}>

                <Card >
                    <CardHeader

                        title="Transaction Details"
                    >

                    </CardHeader>

                    <CardContent>
                  

                        <Grid container spacing={3}>
                       

                            <Grid item xs={12}>
                                <TextField
                                    error={tstate.err.transaction_name}
                                    helperText={tstate.catspn}
                                    type="text"
                                    label="Transaction Name"
                                    fullWidth
                                    name="transaction_name"
                                    variant="outlined"
                                    value={tstate.catJsnDta.transaction_name}
                                    onChange={(event) =>
                                        setTstate(prevState => ({
                                            ...tstate,
                                            catspn: "",
                                            err: { transaction_name: false },
                                            catJsnDta: {
                                                ...prevState.catJsnDta,
                                                transaction_name: event.target.value
                                            }
                                        }))
                                    }
                                    required
                                    autoFocus
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={8} justify="flex-end">
                            <Grid item  >
                                <Button variant="contained" color="primary"
                                    onClick={handleSubmit}
                                // type="submit"
                                >
                                    Update
                        </Button>
                            </Grid>
                        </Grid>
                     
                    </CardContent>

                </Card>

            </Dialog>

            <MUIDataTable
                title={
                    <div>
                    <TextField
                        name="module_id"
                        select
                        label="Module"
                        // variant="outlined"
                        value={lstate.module_id}
                               onChange={(event) => gettranslist(event)
                            }
                        fullWidth
                    >
                        {state.user.Module.map((option) => (
                            <MenuItem key={option.module_id} value={option.module_id}>
                                {option.module_name}
                            </MenuItem>
                        ))}
                     
                    </TextField>
                       <h2>Transaction List</h2>
                       </div>
                }
                data={lstate.catdta}
                columns={columns}
                options={options}
            />


            {/* </Container> */}
        </div>
    )

}
