import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import StarBorder from "@material-ui/icons/StarBorder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AirplayIcon from '@material-ui/icons/Airplay';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DevicesIcon from '@material-ui/icons/Devices';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  icon:{
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
  }
}));

export default function Menu(props) {

  const {menu, title } = props;

  console.log(menu);

  const [mcheck, setMcheck] = React.useState(() => {
    let m = []
    menu.forEach(element => {
      element.items.forEach(e => {
        console.log(e.name)
        m.push({ [e.name]: false })
      })
    });
    return m
  });

  const classes = useStyles();

  const handleClick = (e, key, val) => {
    let g = mcheck[key];
    g[e] = val;
    setMcheck([...mcheck.slice(0, key), g, ...mcheck.slice(key + 1)]);
  };

  const putIcon = (name) => {
    console.log(name)
    if (name === "Application") {
      return (
        <ViewModuleIcon />
      )
    } else if (name === "Transaction") {
      return (
        <AccountBalanceWalletIcon />
      )
    } else if (name === "Role") {
      return (
        <AirplayIcon />
      )
    } else if (name === "User") {
      return (
        <PeopleAltIcon />
      )
    } else if (name === "SmellCells") {
      return (
        // <img src="img/icon/Icon_smellcells.svg" className={classes.icon} />
        <DevicesIcon />
      )
    } else if (name === "Customer") {
      return (
        <img src="img/icon/Icon_customer.svg" className={classes.icon} />
      )
    } else if (name === "Business User") {
      return (
        <img src="img/icon/Icon_business .svg" className={classes.icon} />
      )
    } else if (name === "Support User") {
      return (
        <img src="img/icon/Icon_support.svg" className={classes.icon} />
      )
    } else if (name === "Administrator-v1" || name === "Administrator-v2" ) {
      return (
        <SupervisorAccountIcon />
      )
    } else if (name === "Add Module" || name === "Add Transaction" || name === "Add Role") {
      return (
        <AddToQueueIcon />
      )
    } else if (name === "Module List" || name === "Transaction List" || name === "Role List" || name === "User List") {
      return (
        <FormatListBulletedIcon />
      )
    }
    else {
      return (
        <InboxIcon />
      )
    }
  }

  return (
    <div>
      {/* <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        key={list.id}
        subheader={
          <ListSubheader>Dashboard</ListSubheader>
        }
      >

      </List> */}
      {menu.map(list => {

        return (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            key={list.id}
            subheader={
              <ListSubheader style={{fontSize:'1.1rem',display:title?'block':'none'}}>{list.title}</ListSubheader>
            }
          >
            {list.items.map((item, key) => {
              return (
                <div key={item.id}>
                  {item.subitems != null ? (
                    <div key={item.id}>
                      <ListItem button key={item.id} onClick={() => handleClick(item.name, key, !mcheck[key][item.name])}>
                        <ListItemIcon>
                          {
                            putIcon(item.name)
                          }
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                        {mcheck[key][item.name] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={mcheck[key][item.name]} timeout="auto" key={list.items.id} unmountOnExit>
                        <List component="div" disablePadding>
                          {item.subitems.map(sitem => {
                            return (
                              <ListItem button component={Link} to={sitem.id} key={sitem.id} className={classes.nested}>
                                <ListItemIcon>
                                  {/* <StarBorder /> */}
                                  {
                                    putIcon(sitem.name)
                                  }
                                </ListItemIcon>
                                <ListItemText primary={sitem.name} />
                              </ListItem>
                            );
                          })
                          }
                        </List>
                      </Collapse>
                    </div>
                  ) : (
                    <ListItem
                      button
                      // onClick={handleClick(
                      //     item.name
                      // )}
                      key={item.id}
                    >
                      <ListItemText
                        primary={item.name}
                      />
                    </ListItem>
                  )}
                </div>
              )
            })}
            <Divider key={list.id} absolute />
          </List>
        )
      })}
    </div>
  )

}
