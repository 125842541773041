
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const fltbutton = {
    position: 'fixed',
    zIndex: 1,
    width: '60px',
    height: '60px',
    bottom: '60px',
    right: '40px',
}


class RoleListTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            catdta: [],
            model: false,
            dalog: false,
            rows: [],
            serresp: [],
            opn: false,
            chkbx: {},
            catJsnDta: {},
            module_id: '',
            checked: [],
            trnsresp: [],
            chkdtrnsresp: [],
            snkbrdta: { sts: "", msg: "" },
            catspn: "", profspn: "",
            err: {
                rolename: false, proftyp: false
            }
        };

        this.handleFldChange = this.handleFldChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlactv = this.handlactv.bind(this);
        this.handleReAsignTrans = this.handleReAsignTrans.bind(this);
    }

    addRow = (value) => {
        this.setState(state => {
            return {
                rows: [...this.state.rows, value]
            };
        });
    };

    handleToggle = (value) => () => {
        const currentIndex = this.state.checked.indexOf(value.transaction_id);
        const newChecked = [...this.state.checked];
        console.log(this.state.checked);
        if (currentIndex === -1) {
            newChecked.push(value.transaction_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // setChecked(newChecked);
        this.setState({
            checked: newChecked
        });
        console.log(this.state.checked);
    };

    handleFldChange(event) {
        let dta = this.state.catJsnDta;
        dta.active = !this.state.catJsnDta.active;
        this.setState({
            catJsnDta: dta
        });
    }

    handlechange = (event) => {
        let dta = this.state.catdta[event.currentTarget.value];
        dta = Object.assign({
            rwindx: event.currentTarget.value
        }, dta);
        axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/getRoleAccessDetails', { "role_id": dta.roleid }).then((response) => {
            console.log(response);
            // let altrns=[];
            let chktrns = [];
            // {response.data.result[0].allTranslist.map((value) => {
            //     altrns.push(value.roleid)

            // })}
            {
                response.data.result[0].checkedTranslist.map((value) => {
                    chktrns.push(value.transaction_id)

                })
            }
            this.setState({
                catJsnDta: dta, trnsresp: response.data.result[0].allTranslist,
                checked: chktrns, chkdtrnsresp: response.data.result[0].checkedTranslist,
                module_id: response.data.result[0].module_id
            });
            console.log(this.state.checked);
            this.handleClose();
        });

    }

    handleSubmit() {
        this.setState({ catspn: "", profspn: "" });
        if (this.state.catJsnDta.rolename === null || this.state.catJsnDta.rolename === '') {

            this.setState({ catspn: "Role name is Required", err: { rolename: true } });
        } else {
            console.log(this.state.catJsnDta);
            let tst = this.state.catJsnDta;
            // console.log(tst.amt.props.value);

            let dta = this.state.catdta;
            var mdData = tst
            console.log(mdData);
            axios.put('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/Role', tst).then((response) => {
                console.log(response);
                if (response.data.error !== true) {
                    dta[tst.rwindx] = tst;
                    console.log(dta);
                    this.setState({
                        catdta: dta
                    });
                    this.setState({
                        snkbrdta: { sts: "success", msg: "Role Id: " + [this.state.catJsnDta.roleid] + ", " + response.data.message }
                    });
                    this.handleClose();
                    this.handleCloseSnk();

                } else {
                    this.setState({
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    this.handleCloseSnk();
                }

            });
        }
    }
    handleReAsignTrans() {

        if (this.state.checked.length < 1 ||
            this.state.checked.length === this.state.chkdtrnsresp.length) {
            // alert(this.state.chkdtrnsresp.length)
            this.setState({
                snkbrdta: { sts: "error", msg: "Please check atleast one Transaction" }
            });
        } else {
            let tst = this.state.catJsnDta;
            // var mdData = tst
            console.log(tst);
            let newpayload = { 'role_id': tst.roleid, 'module_id': this.state.module_id, 'transaction_id': this.state.checked };
            console.log(newpayload);
            axios.post('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/updatetransactionAccess', newpayload).then((response) => {
                console.log(response);
                if (response.data.error !== true) {
                    this.setState({
                        snkbrdta: { sts: "success", msg: "Updated Successfully" }
                    });
                    this.handleClose();
                    this.handleCloseSnk();

                } else {
                    this.setState({
                        snkbrdta: { sts: "error", msg: response.data.message }
                    });
                    this.handleCloseSnk();
                }

            });
        }
    }
    handlactv(event) {


        let dta = this.state.catdta;
        let data = dta[event.currentTarget.value];

        data.active = !this.state.catdta[event.currentTarget.value].act.props.checked;
        console.log(data);
    }

    componentDidMount() {
        axios.get('https://api.kiratitsolutions.net:8443/bdsUsrmgmt/roleList').then((response) => {
            // event.preventDefault();
            console.log(response);
            this.setState({ catdta: response.data.result });

        });

    }



    componentWillUnmount() {
        //this.eventSource.removeAllListeners();
        //this.eventSource.close();
    }

    handleHistPush = () => {
        //  history.push(`/catagory`);
    };

    handleClose = () => {
        this.setState({
            model: !this.state.model
        });
    };

    handleCloseSnk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            dalog: !this.state.dalog
        });
    };

    render() {

        const columns = [
            {
                name: "roleid",
                label: "ID",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    sort: true,
                    draggable: true,
                }
            },
            {
                name: "rolename",
                label: "Role Name",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    sort: true,
                    draggable: true,
                }
            }
            ,
            {
                name: "action",
                label: "Action",
                options: {
                    filter: true,
                    sort: false,
                    draggable: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <IconButton
                                id={tableMeta.rowData[0]}
                                aria-label="add"
                                // onClick={(event) => this.handlechange(idy)}
                                onClick={this.handlechange}
                                value={tableMeta.rowIndex}
                            >
                                <VisibilityIcon
                                />
                            </IconButton>
                            //        <button onClick={() => window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)}>
                            //   Edit
                            // </button>
                        )
                    }
                },


            },

        ];

        const options = {
            // filter: true,
            //   filterType: 'dropdown',
            responsive: 'vertical',
            sortOrder: {
                name: 'id',
                direction: 'asc'
            },
            draggableColumns: {
                enabled: true
            },
            selectableRows: false
        };

        return (

            <div>
            {/* <Container fixed> */}
                <Snackbar open={this.state.dalog} autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={this.handleCloseSnk} >
                    <Alert severity={this.state.snkbrdta.sts} onClose={this.handleCloseSnk}

                        variant="filled">
                        {this.state.snkbrdta.msg}
                    </Alert>
                </Snackbar>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title"
                    fullWidth={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={this.state.model}>

                    <Card >
                        <CardHeader

                            title="Role Details"
                        >

                        </CardHeader>

                        <CardContent>
                            {/* <form> */}

                            <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                    <Accordion >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                            <div >
                                                <Typography >Role</Typography>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <Container fixed>
                                                <Grid container spacing={3}>


                                                    <Grid item xs={12} >
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    error={this.state.err.rolename}
                                                                    helperText={this.state.catspn}
                                                                    type="text"
                                                                    label="Role Name"
                                                                    fullWidth
                                                                    name="rolename"
                                                                    variant="outlined"
                                                                    value={this.state.catJsnDta.rolename}
                                                                    onChange={(event) =>
                                                                        this.setState(prevState => ({
                                                                            catspn: "",
                                                                            err: { rolename: false },
                                                                            catJsnDta: {
                                                                                ...prevState.catJsnDta,
                                                                                rolename: event.target.value
                                                                            }
                                                                        }))
                                                                    }
                                                                    required
                                                                    autoFocus
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>


                                                <Grid container spacing={8} justify="flex-end">
                                                    <Grid item  >
                                                        <Button variant="contained" color="primary"
                                                            onClick={this.handleSubmit}
                                                        // type="submit"
                                                        >
                                                            Update
                                </Button>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                            <div >
                                                <Typography >Transactions</Typography>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <Container fixed>
                                                <FormGroup row>
                                                {this.state.trnsresp.map((value) => {
                                                        const labelId = `checkbox-list-label-${value}`;
                                                        return (
                                                    <FormControlLabel
                                                    onClick={this.handleToggle(value)}
                                                        control={<Checkbox 
                                                            checked={this.state.checked.indexOf(value.transaction_id) !== -1}
                                                         name="checkedA" />}
                                                         label={value.transaction_name} 
                                                    />
                                                    );
                                                })}
                                                </FormGroup>
                                                <Grid container spacing={8} justify="flex-end">
                                                    <Grid item  >
                                                        <Button variant="contained" color="primary"
                                                            onClick={this.handleReAsignTrans}
                                                        // type="submit"
                                                        >
                                                            Re-Assign
                                </Button>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                            {/* </form> */}
                        </CardContent>
                    </Card>

                </Dialog>

                <MUIDataTable
                    title={"Roles"}
                    data={this.state.catdta}
                    columns={columns}
                    options={options}
                />

            {/* </Container> */}
            </div>

        );
    }

}

export default RoleListTable;
